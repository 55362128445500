import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _51ef49c8 = () => interopDefault(import('../../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _557b46de = () => interopDefault(import('../../pages/academic-bank/index.vue' /* webpackChunkName: "pages/academic-bank/index" */))
const _23417254 = () => interopDefault(import('../../pages/academic-bank/index/index.vue' /* webpackChunkName: "pages/academic-bank/index/index" */))
const _c8be01b0 = () => interopDefault(import('../../pages/academic-bank/index/courses/_id/index.vue' /* webpackChunkName: "pages/academic-bank/index/courses/_id/index" */))
const _2b7287e4 = () => interopDefault(import('../../pages/articles/index.vue' /* webpackChunkName: "pages/articles/index" */))
const _31b3da18 = () => interopDefault(import('../../pages/boards/index.vue' /* webpackChunkName: "pages/boards/index" */))
const _25b98bad = () => interopDefault(import('../../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _f80e7b22 = () => interopDefault(import('../../pages/checkout/index/order/index.vue' /* webpackChunkName: "pages/checkout/index/order/index" */))
const _224377b4 = () => interopDefault(import('../../pages/checkout/index/event/_id/index.vue' /* webpackChunkName: "pages/checkout/index/event/_id/index" */))
const _ef9a8b54 = () => interopDefault(import('../../pages/checkout/index/marathon/_id/index.vue' /* webpackChunkName: "pages/checkout/index/marathon/_id/index" */))
const _4960f6c0 = () => interopDefault(import('../../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _4985c7f2 = () => interopDefault(import('../../pages/loan-shops/index.vue' /* webpackChunkName: "pages/loan-shops/index" */))
const _5b8affa8 = () => interopDefault(import('../../pages/marathons/index.vue' /* webpackChunkName: "pages/marathons/index" */))
const _d70368b0 = () => interopDefault(import('../../pages/personal-zone/index.vue' /* webpackChunkName: "pages/personal-zone/index" */))
const _17125dc9 = () => interopDefault(import('../../pages/personal-zone/index/account/index.vue' /* webpackChunkName: "pages/personal-zone/index/account/index" */))
const _b621da42 = () => interopDefault(import('../../pages/personal-zone/index/orders/index.vue' /* webpackChunkName: "pages/personal-zone/index/orders/index" */))
const _1c8356e2 = () => interopDefault(import('../../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _8f68c068 = () => interopDefault(import('../../pages/shops/index.vue' /* webpackChunkName: "pages/shops/index" */))
const _200636be = () => interopDefault(import('../../pages/terms-of-use/index.vue' /* webpackChunkName: "pages/terms-of-use/index" */))
const _36c5f98b = () => interopDefault(import('../../pages/thank-you/index.vue' /* webpackChunkName: "pages/thank-you/index" */))
const _a28193f8 = () => interopDefault(import('../../pages/thank-you/index/event/_id/index.vue' /* webpackChunkName: "pages/thank-you/index/event/_id/index" */))
const _ab75d890 = () => interopDefault(import('../../pages/thank-you/index/marathon/_id/index.vue' /* webpackChunkName: "pages/thank-you/index/marathon/_id/index" */))
const _9ac40090 = () => interopDefault(import('../../pages/thank-you/index/order/_id/index.vue' /* webpackChunkName: "pages/thank-you/index/order/_id/index" */))
const _362c553f = () => interopDefault(import('../../pages/transparency/index.vue' /* webpackChunkName: "pages/transparency/index" */))
const _6b67570d = () => interopDefault(import('../../pages/Payment/Failure.vue' /* webpackChunkName: "pages/Payment/Failure" */))
const _b75afdf4 = () => interopDefault(import('../../pages/Payment/Success.vue' /* webpackChunkName: "pages/Payment/Success" */))
const _1f31561e = () => interopDefault(import('../../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4a5fefc2 = () => interopDefault(import('../../pages/articles/_slug/index.vue' /* webpackChunkName: "pages/articles/_slug/index" */))
const _35211ca8 = () => interopDefault(import('../../pages/boards/_slug.vue' /* webpackChunkName: "pages/boards/_slug" */))
const _b53e9caa = () => interopDefault(import('../../pages/events/_id/index.vue' /* webpackChunkName: "pages/events/_id/index" */))
const _e6c5c9b4 = () => interopDefault(import('../../pages/forms/_id.vue' /* webpackChunkName: "pages/forms/_id" */))
const _49199230 = () => interopDefault(import('../../pages/loan-products/_slug.vue' /* webpackChunkName: "pages/loan-products/_slug" */))
const _4cf30a82 = () => interopDefault(import('../../pages/loan-shops/_slug.vue' /* webpackChunkName: "pages/loan-shops/_slug" */))
const _ab80b5d2 = () => interopDefault(import('../../pages/marathons/_id/index.vue' /* webpackChunkName: "pages/marathons/_id/index" */))
const _778b6103 = () => interopDefault(import('../../pages/products/_slug.vue' /* webpackChunkName: "pages/products/_slug" */))
const _61c3f146 = () => interopDefault(import('../../pages/reset-phone/_token.vue' /* webpackChunkName: "pages/reset-phone/_token" */))
const _92d602f8 = () => interopDefault(import('../../pages/shops/_slug.vue' /* webpackChunkName: "pages/shops/_slug" */))
const _019851b8 = () => interopDefault(import('../../pages/marathons/_id/invitation.vue' /* webpackChunkName: "pages/marathons/_id/invitation" */))
const _c2651986 = () => interopDefault(import('../../pages/posts/_id/delete/_code.vue' /* webpackChunkName: "pages/posts/_id/delete/_code" */))
const _0db42d41 = () => interopDefault(import('../../pages/qr-codes/_userId/_eventId.vue' /* webpackChunkName: "pages/qr-codes/_userId/_eventId" */))
const _1d7ab4d6 = () => interopDefault(import('../../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _51ef49c8,
    name: "about"
  }, {
    path: "/academic-bank",
    component: _557b46de,
    children: [{
      path: "",
      component: _23417254,
      name: "academic-bank-index"
    }, {
      path: "courses/:id",
      component: _c8be01b0,
      name: "academic-bank-index-courses-id"
    }]
  }, {
    path: "/articles",
    component: _2b7287e4,
    name: "articles"
  }, {
    path: "/boards",
    component: _31b3da18,
    name: "boards"
  }, {
    path: "/checkout",
    component: _25b98bad,
    name: "checkout",
    children: [{
      path: "order",
      component: _f80e7b22,
      name: "checkout-index-order"
    }, {
      path: "event/:id",
      component: _224377b4,
      name: "checkout-index-event-id"
    }, {
      path: "marathon/:id",
      component: _ef9a8b54,
      name: "checkout-index-marathon-id"
    }]
  }, {
    path: "/events",
    component: _4960f6c0,
    name: "events"
  }, {
    path: "/loan-shops",
    component: _4985c7f2,
    name: "loan-shops"
  }, {
    path: "/marathons",
    component: _5b8affa8,
    name: "marathons"
  }, {
    path: "/personal-zone",
    component: _d70368b0,
    name: "personal-zone",
    children: [{
      path: "account",
      component: _17125dc9,
      name: "personal-zone-index-account"
    }, {
      path: "orders",
      component: _b621da42,
      name: "personal-zone-index-orders"
    }]
  }, {
    path: "/search",
    component: _1c8356e2,
    name: "search"
  }, {
    path: "/shops",
    component: _8f68c068,
    name: "shops"
  }, {
    path: "/terms-of-use",
    component: _200636be,
    name: "terms-of-use"
  }, {
    path: "/thank-you",
    component: _36c5f98b,
    name: "thank-you",
    children: [{
      path: "event/:id",
      component: _a28193f8,
      name: "thank-you-index-event-id"
    }, {
      path: "marathon/:id",
      component: _ab75d890,
      name: "thank-you-index-marathon-id"
    }, {
      path: "order/:id",
      component: _9ac40090,
      name: "thank-you-index-order-id"
    }]
  }, {
    path: "/transparency",
    component: _362c553f,
    name: "transparency"
  }, {
    path: "/Payment/Failure",
    component: _6b67570d,
    name: "Payment-Failure"
  }, {
    path: "/Payment/Success",
    component: _b75afdf4,
    name: "Payment-Success"
  }, {
    path: "/",
    component: _1f31561e,
    name: "index"
  }, {
    path: "/articles/:slug",
    component: _4a5fefc2,
    name: "articles-slug"
  }, {
    path: "/boards/:slug",
    component: _35211ca8,
    name: "boards-slug"
  }, {
    path: "/events/:id",
    component: _b53e9caa,
    name: "events-id"
  }, {
    path: "/forms/:id?",
    component: _e6c5c9b4,
    name: "forms-id"
  }, {
    path: "/loan-products/:slug?",
    component: _49199230,
    name: "loan-products-slug"
  }, {
    path: "/loan-shops/:slug?",
    component: _4cf30a82,
    name: "loan-shops-slug"
  }, {
    path: "/marathons/:id",
    component: _ab80b5d2,
    name: "marathons-id"
  }, {
    path: "/products/:slug?",
    component: _778b6103,
    name: "products-slug"
  }, {
    path: "/reset-phone/:token?",
    component: _61c3f146,
    name: "reset-phone-token"
  }, {
    path: "/shops/:slug",
    component: _92d602f8,
    name: "shops-slug"
  }, {
    path: "/marathons/:id/invitation",
    component: _019851b8,
    name: "marathons-id-invitation"
  }, {
    path: "/posts/:id?/delete/:code?",
    component: _c2651986,
    name: "posts-id-delete-code"
  }, {
    path: "/qr-codes/:userId?/:eventId?",
    component: _0db42d41,
    name: "qr-codes-userId-eventId"
  }, {
    path: "/:slug",
    component: _1d7ab4d6,
    name: "slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
